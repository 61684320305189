<template>
  <v-row dense>
    <v-col cols="12">
      <v-subheader class="primary--text font-weight-medium px-0">
        <slot></slot>
      </v-subheader>
      <slot name="content"></slot>
    </v-col>
  </v-row>
</template>

<script>
export default {}
</script>